<template>
	<div id="uplodimg">
		<el-upload
			:class="{ hidden: ishidden }"
			:action="imgupUrl"
			ref="newupload"
			:drag="isdrag"
			:multiple="imgUpmultiple"
			:accept="accept"
			:limit="limitNum"
			:file-list="productImgs"
			:list-type="listtype"
			:on-preview="handlePictureCardPreview"
			:on-remove="handleRemove"
			:on-success="handleSuccess"
			:before-upload="beforeUpload"
			:on-change="onChange"
			:on-exceed="handleExceed"
			:on-error="imgUploadError"
		>
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible" :modal="false">
			<img style="max-width: 100%;max-height: 100%;" :src="dialogImageUrl" mode="aspectFit" /> 
		</el-dialog>
	</div>
</template>

<script>
	const baseUrl = process.env.VUE_APP_API=="/"?'/api':process.env.VUE_APP_API
export default {
	props: {
		imgurl: {
			type: String,
			default: ''
		},
		items: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			imgupUrl: baseUrl+'/file-upload/upload', //"https://jsonplaceholder.typicode.com/posts/",api
			// imgupUrl:'/api/file-upload/upload', //"https://jsonplaceholder.typicode.com/posts/",api
			isdrag: false,
			dialogVisible: false,
			imgUpmultiple: false,
			ishidden: false,
			isshow:true,
			limitNum:1,
			accept: 'image/*',
			dialogImageUrl: '',
			productImgs: [],
			listtype: 'picture-card', //text/picture/
			imgUrlData: [],
			uploadImgurl: ''
		};
	},
	watch: {
		imgurl(val) {
			// console.log(old)
			let imgArr = [];
			if (val) {
				val.split(',').forEach(item => {
					imgArr.push({ uid: '', url: item });
				});
				this.productImgs = imgArr;
				if (this.productImgs.length > this.limitNum || this.productImgs.length == this.limitNum) {
					this.ishidden = true;
				}
			}
		}
	},
	created() {
		if(this.items.showType=='mImgUploader'){
			this.imgUpmultiple = true
		}
		if(this.items.limitNum)
			this.limitNum = parseInt(this.items.limitNum);
		if (this.imgurl) {
			let firstImgArr = [];
			this.imgurl.split(',').forEach(item => {
				firstImgArr.push({ uid: '', url: item });
			});
			this.productImgs = firstImgArr;
			if (this.productImgs.length > this.limitNum || this.productImgs.length == this.limitNum) {
				this.ishidden = true;
			}
		}
	},
	methods: {
		distinct(a, b) {
			let arr = a.concat(b);
			let result = [];
			let obj = {};
			for (let i of arr) {
				if (!obj[i]) {
					result.push(i);
					obj[i] = 1;
				}
			}
			return result;
		},
		handleRemove(file,fileList) {
			this.deleteItem(file.uid, this.productImgs);
			this.$emit('delcallback', this.productImgs, this.items.prop,this.imgUpmultiple);
			if (fileList.length < this.limitNum) {
				this.ishidden = false;
			}
		},
		deleteItem(uid, list) {
			list.forEach((item, index) => {
				if (item.uid === uid) {
					list.splice(index, 1);
				}
			});
		},
		handlePictureCardPreview(file) {
			// this.imgShow(file.url)
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		beforeUpload(file) {
			//文件上传之前调用做一些拦截限制
			// const isJPG = true;
			// const isJPG = file.type === 'image/jpeg';
			// const isLt2M = file.size / 1024 / 1024 < 2;
			// if (!isJPG) {
			// 	this.$message.error('上传头像图片只能是 JPG 格式!');
			// }
			// if (!isLt2M) {
			// 	this.$message.error('上传图片大小不能超过 2MB!');
			// }
			// return isJPG && isLt2M;
		},
		handleSuccess(res, file) {
			//图片上传成功
			// this.productImgs.push({url:res})
			this.$emit('oploadBack',this.productImgs,res,this.items.prop,this.imgUpmultiple);
		},
		handleExceed(files, fileList) {
			//图片上传超过数量限制
			this.$message.error('只能上传' + this.limitNum + '张图片!');
		},
		imgUploadError(err, file, fileList) {
			//图片上传失败调用
			this.$message.error('上传图片失败!');
		},
		onChange(file, fileList) {
			// this.$emit('uploadChange', this.productImgs);
			if (fileList.length > this.limitNum || fileList.length == this.limitNum) {
				this.ishidden = true;
			}
		}
		// imgUpload(params){//自定义上传路径
		// 	const formdata = new FormData();
		// 	const file = params.file
		// 	axios.post("http://localhost:29674/Upload/UploadImgHandler.ashx?action=newupload")
		// 	.then(function(dataret) {
		// 		console.log(dataret)
		// 		alert("上传成功")
		// 	}).catch((err)=>{
		// 		alert("err")
		// 	})
		// },
		// upimg(){
		// 	this.$refs.newupload.submit()
		// }
	}
};
</script>

<style lang="scss">
	.el-upload.el-upload--picture-card{
		line-height: 126px;
	}
	.hidden .el-upload.el-upload--picture-card {
		display: none !important;
	}
	.viewimgbox{
		position: fixed;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #000088;
	}
	.widthshow{
		max-width: 100%;
		height: 100%;
	}
	.heightshow{
		height: 100%;
	}
	// .el-dialog__body{
	// 	height:480px;
	// }
	#generalList #uplodimg .el-dialog__wrapper {
		// overflow: auto !important; 
		bottom:0 !important;
		top:0 !important;
		height: 100%;
	}
	#generalList #uplodimg .el-dialog{
		width: 80%;
		height: 96%;
		margin-top: 10px !important;
		padding-bottom: 65px;
	}
	#generalList #uplodimg .el-dialog__body{
		// padding-right: 40px !important;
		height: 100%;
		padding: 10px !important;
		text-align: center;
	}
	#generalList #uplodimg .dialog-box{
		border: 1px solid #ebebeb;
		padding: 20px 20px 10px 0; 
		height: 100%;
		overflow: auto;
	}
</style>
